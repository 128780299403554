import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import PropTypes from 'prop-types';

const DataTable = ({test_type, patientDetails, setCurrentTest}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Test</TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Status</TableCell>
                </TableHead>
                <TableBody>
                    {test_type?.map(({id, test_name, status}, index: Number) => (
                        <TableRow
                            key={id}
                            id={id}
                            onClick={() => {
                                setCurrentTest(test_type[index]);
                            }}
                        >
                            <TableCell>{test_name}</TableCell>
                            <TableCell>{patientDetails.fname} {patientDetails.lname}</TableCell>
                            <TableCell>{status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

DataTable.defaultProps = {
    test_type: [],
    patientDetails: {},
    setCurrentTest: () => {
    },
}

DataTable.propTypes = {
    test_type: PropTypes.array.isRequired,
    patientDetails: PropTypes.object.isRequired,
    setCurrentTest: PropTypes.func.isRequired

}

export default DataTable;
