import React, {useEffect, useState} from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from "@mui/material";
import API from "../../api/api"
import {ExpandMore} from "@mui/icons-material";
import PatientCard from "../../Components/PatientCard";
import DataTable from "./components/DataTable";
import TestResults from "./components/ResultsInput";


function LabSession({patient_id, patientDetails}) {
    const [patients, setPatients] = useState([])
    const [lab, setLab] = useState([])
    const [procedure, setProcedure] = useState([])
    const [imaging, setImaging] = useState([])
    const [currentLab, setCurrentLab] = useState("")
    const [currentImage, setCurrentImage] = useState("")
    const [currentProcedure, setCurrentProcedure] = useState("")
    // const [patientDetails, setPatientDetails] = useState({})
    const [labResults, setLabResults] = useState("")
    const [imageResults, setImageResults] = useState("")
    const [procedureResults, setProcedureResults] = useState("")
    const [selectRow, setRow] = useState(null)
    const [file, setFile] = useState(null);

    const [updatedTests, setUpdatedTests] = useState([]);
    const [totalTests, setTotalTests] = useState(0);

    useEffect(() => {
        console.log("Updated Tests:", updatedTests); // Log whenever updatedTests changes
    }, [updatedTests]);

    useEffect(() => {
        API.put("laboratory/medicaltests/", {
            "patient": patient_id,
            "staff": localStorage.getItem("user_id"),
            "institution": localStorage.getItem('institution_id')
        }).then((resp) => {
            const labs = [];
            const imaging_objects = [];
            const procedures = [];

            for (let i = 0; i < resp.data.length; i++) {
                if (resp.data[i]["test_type"] === "lab") {
                    labs.push(resp.data[i]);
                } else if (resp.data[i]["test_type"] === "imaging") {
                    imaging_objects.push(resp.data[i]);
                } else if (resp.data[i]["test_type"] === "procedure") {
                    procedures.push(resp.data[i]);
                }
            }

            setTotalTests(resp.data.length);
            setLab(labs);
            setImaging(imaging_objects);
            setProcedure(procedures);
        });
    }, [patient_id]);

    const handleDoneWithPatient = () => {
        // Assuming completedTests is a count of tests that have been updated
        if (updatedTests.length < totalTests) {
            alert("Some tests are still pending. Please complete all tests before proceeding.");
        } else {
            // Proceed if all tests are completed
            alert("All tests have been completed. You can proceed.");
            window.location.reload();
        }
    }



    function updateTest(test, results, attachment) {
        // Receives the test `object` and the results that need to be updated.
        // It also sets the status of the medical test to `completed`
        let form_data = new FormData()
        form_data.append("test_id", test["id"])
        form_data.append("results", String(results))
        if (attachment) {
            form_data.append("attachments", attachment);
        }
        form_data.append("status", "pending_review")
        form_data.append("staff", test["staff"])
        form_data.append("handler", localStorage.getItem("user_id"))
        form_data.append("institution", localStorage.getItem("institution_id")) // Data can be found in test,
        // but this is just to be safe and ensure the tests show where the results are coming from
        form_data.append("patient", test["patient"])

        if (results === "") {
            alert("Please enter results")
            return
        }
        API.patch("laboratory/medicaltests/", form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(resp => {
                if (resp.status === 206) {
                    const testId = form_data.get("test_id");
                    // add test id to updatedTests
                    setUpdatedTests(prevUpdatedTests =>
                        prevUpdatedTests.includes(testId) ? prevUpdatedTests : [...prevUpdatedTests, testId]
                    );
                    console.log(updatedTests);
                    alert("Test results updated successfully")
                }
            })
        setLabResults("")
        setProcedureResults("")
        setImageResults("")
    }

    useEffect(() => {
            API.get("laboratory/medicaltests/get_patients_with_tests/", {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    "staff": localStorage.getItem("user_id"),
                    "institution": localStorage.getItem("institution_id")
                }
            })
                .then((resp) => {
                        setPatients(resp.data)
                        // console.log(resp.data)
                    }
                ).catch((e) => {
                console.log(e)
            })
        }, []
    )

    const isMobile = useMediaQuery('(max-width:600px)');

    const accordionStyle = {
        borderRadius: 15,
        minWidth: isMobile ? '100%' : 500,
        marginBottom: isMobile ? '1rem' : 0
    };

    let labTechView = (
        <Grid container rowSpacing={2}>
            <Grid item container spacing={2} justifyContent={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <PatientCard patientInfo={patientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Tests List</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Patient</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patients.length > 0 ? (
                                            patients.map(({ first_name, last_name, id }) => (
                                                <TableRow
                                                    key={id}
                                                    hover
                                                    selected={id === selectRow}
                                                    onClick={() => {
                                                        // selectPatient(id, { "fname": first_name, "lname": last_name });
                                                        setRow(id);
                                                        setCurrentProcedure("");
                                                        setCurrentImage("");
                                                        setCurrentLab("");
                                                    }}
                                                >
                                                    <TableCell>{`${first_name} ${last_name}`}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="h6">No Pending Tests</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            {/** Lab Tests Section */}
            <Grid item container spacing={2} justifyContent={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Lab Tests</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataTable
                                test_type={lab}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentLab}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Lab Test Results</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentLab}
                                testResults={labResults}
                                setTestResults={setLabResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            {/** Imaging Section */}
            <Grid item container spacing={2} justifyContent={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Imaging</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataTable
                                test_type={imaging}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentImage}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Imaging Results</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentImage}
                                testResults={imageResults}
                                setTestResults={setImageResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            {/** Procedures Section */}
            <Grid item container spacing={2} justifyContent={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Procedures</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataTable
                                test_type={procedure}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentProcedure}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Accordion sx={accordionStyle}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Procedure Results</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentProcedure}
                                testResults={procedureResults}
                                setTestResults={setProcedureResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Button
                    sx={{
                        borderRadius: 10,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        width: "100%",
                        mt: 2
                    }}
                    onClick={() => handleDoneWithPatient()}
                >
                    <Typography variant="body1">Done with patient</Typography>
                </Button>
            </Grid>
        </Grid>
    )

    return (
        labTechView
    )
}

export default LabSession;