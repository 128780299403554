import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import PropTypes from 'prop-types';

const ReviewTable = ({test_type, selectCurrentTest, setOpenRev}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Test</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Report</TableCell>
                </TableHead>
                <TableBody>
                    {test_type.length > 0 ? test_type.map((test) => (
                        <TableRow key={test.id}>
                            <TableCell>{test.test_name}</TableCell>
                            <TableCell>{new Date (test.last_updated).toDateString()}</TableCell>
                            <TableCell>
                                <button onClick={() => {
                                    selectCurrentTest(test.id)

                                    setOpenRev(true)
                                }
                                }>Review</button>
                            </TableCell>
                        </TableRow>
                    )) : <TableCell>
                        <Typography variant={"h6"}>
                            No data to show
                        </Typography>
                    </TableCell>}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ReviewTable.defaultProps = {
    test_type: [],
    selectCurrentTest: () => {
    },
}

ReviewTable.propTypes = {
    test_type: PropTypes.array.isRequired,
    selectCurrentTest: PropTypes.func.isRequired

}

export default ReviewTable;
