import React from 'react';
import {Box, Button, Divider, Modal, TextField, Typography} from '@mui/material';
import API from "../../../api/api";


function updateTestStatus(test_id, handleClose) {
    API.patch(`${process.env.REACT_APP_URL}laboratory/medicaltests/update_test_status/`, {
        "institution_id": localStorage.getItem("institution_id"),
        "test_id": test_id,
        "status": "completed"
    }).then((res) => {
        console.log(res)
        if (res.status === 200) {
            alert("Test status updated successfully")
            handleClose();
        } else {
            alert("Test status update failed")
        }
    }).catch((err) => {
        console.log(err);
    })
}

const ReviewModal = ({test_type, handleClose, openRev}) => {

    let url = process.env.REACT_APP_URL;
    if (url[url.length - 1] === '/') {
        url = url.slice(0, -1);
    }

    let attachment = test_type.attachments;
    // Remove "api/" from the first part of the attachment url
    if (attachment) {
        attachment = attachment.slice(4);
    }


    return (
        <div>

            <Modal
                open={openRev}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    overflow: 'scroll',
                }}
            >
                <Box
                    style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                        border: '1px solid #000',
                        width: "1050px",
                        height: "500px",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 10,
                        padding: 5,
                        overflow: 'auto',
                    }}
                >

                    <div style={{padding: 24, display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h6">Test Details</Typography>
                        <TextField
                            label="Test Type"
                            value={test_type.test_type}
                            margin="normal"
                            fullWidth
                            disabled
                        />
                        <TextField
                            label="Test Name"
                            value={test_type.test_name}
                            margin="normal"
                            fullWidth
                            disabled
                        />

                        <Divider sx={{mb: 2, borderTopWidth:2}}/>
                        <Typography sx={{textDecoration: "underline"}} variant="h6">Test Results</Typography>
                        <Typography
                            variant="body1"
                            sx={{whiteSpace: 'pre-wrap', fontFamily: 'monospace'}}
                        >
                            {test_type.results}
                        </Typography>

                        <Divider sx={{mb: 2, borderTopWidth:2}}/>
                        {test_type.attachments ? <img
                            src={`${url}${attachment}`}
                            alt={"Attachment"}
                            loading="lazy"
                        /> : <Typography> No Additional Attachment</Typography>}

                        <div>
                            <br/>
                            <Button variant="contained"
                                    onClick={() => {
                                        updateTestStatus(test_type.id, handleClose);

                                    }}
                            >Done reviewing
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ReviewModal;
