import React from 'react';
import {Button, TextField, Typography} from "@mui/material";

const TestResults = ({currentTest, testResults, setTestResults, setFile, file, updateTest}) => {
    return (
        <>
            <Typography>
                Lab Technician Name: {localStorage.getItem("user_first_name")} <span>
        {localStorage.getItem("user_last_name")}</span>
            </Typography>
            <Typography>
                Test Name: {currentTest.test_name}
            </Typography>

            <br/>

            <TextField
                id={"image"}
                label={"Results"}
                variant={"outlined"}
                multiline={"true"}
                style={{
                    minWidth: 400,
                    paddingBottom: 15
                }}
                value={testResults}
                onChange={e => setTestResults(e.target.value)}
                inputProps={{
                    style: {whiteSpace: "pre-wrap"}
                }}
            />
            <br/>
            <input type={"file"}
                   accept={"application/pdf, image/*, text/csv, .csv " +
                       "application/msword, application/vnd.ms-excel, .xlsx, .xls" +
                       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx, .doc"}
                   onChange={(event) => setFile(event.target.files[0])}
            />
            <Button
                style={{
                    borderRadius: 10,
                    backgroundColor: "#da4167",
                    color: "white",
                    marginRight: 5,
                    marginTop: 10
                }}
                onClick={() => updateTest(
                    currentTest,
                    testResults,
                    file
                )
                }
            >
                <Typography variant={"body1"}>
                    Save
                </Typography>
            </Button>
        </>
    );
};

export default TestResults;
